import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby'

export default function Navbar({  }) {
  // const data = useStaticQuery(graphql`
  //     query SiteInfo {
  //       site {
  //         siteMetadata {
  //           copyright
  //           description
  //           title
  //         }
  //       }
  //     }
  //   `)

  // const { title } = data.site.siteMetadata

  return (
    <nav>
      <div className="title">
        <Link to="/">
          {/* <h1 href="/">{title}</h1> */}
          <h1 href="/">XioCode</h1>
        </Link>
      </div>
      
      <div className="links">
        {/* <Link to="/">XioCode</Link> */}
        <Link to="/about">About</Link>
        <Link to="/projects">Projects</Link>
      </div>
    </nav>
  )
}


import React from 'react';
import Layout from '../components/Layout'

function about() {
  return (
    <Layout>
      <div>
        <h1>About Page</h1>
      </div>
    </Layout>
  );
}

export default about;

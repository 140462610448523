import { Link, graphql } from 'gatsby';
import React from 'react';
import Navbar from './navbar';

export default function Layout({ children }) {
  // console.log(data)

  return (
    <div className="layout">
      <Navbar />
      <div className="content">
        {/* content for each pages */}
        {children}
      </div>
      <footer>
        <p>Powered by xiocode.com . </p>
      </footer>
    </div>
  )
}


// // export page query
// export const query = graphql`
//   query LayoutPage {
//     site {
//       siteMetadata {
//         contact
//       }
//     }
//   }
// `